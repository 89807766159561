<template lang="pug">
  .booking-confirmation
    template(v-if="selectedReservationId")
      Form(
        :reservation-id="selectedReservationId"
        @back="unselectReservation"
      )
    template(v-else)
      .container-fluid
        AppOverlayLoader(:state="loading")
        .row
          AppSearch(
            :value="filters.searchValue"
            @update="applyFilter('searchValue', $event)"
          )
        .row.filer-bar
          .filter.col
            p.filter-title {{ $t("ota_management.filters.company") }}
            AppDropdown(
              checkbox
              batch-select
              multiple
              close-on-select
              allow-empty
              :value="filters.companies"
              :items="companies"
              @select="applyFilter('companies', $event)"
            )
          .filter.col
            p.filter-title {{ $t("ota_management.filters.pick_up_shop") }}
            AppDropdown(
              checkbox
              batch-select
              multiple
              close-on-select
              allow-empty
              :value="filters.shops"
              :items="shops"
              @select="applyFilter('shops', $event)"
            )
          .filter.col
            p.filter-title {{ $t("ota_management.filters.status") }}
            AppDropdown(
              checkbox
              batch-select
              multiple
              close-on-select
              allow-empty
            )
          .filter.col
            p.filter-title {{ $t("ota_management.filters.period") }}
            .period
              AppDropdown(
                checkbox
                close-on-select
                allow-empty
                :value="filters.period"
                :items="periods"
                @select="applyFilter('period', $event)"
              )
              AppDatepicker(
                :value="filters.dateRange"
                range
                @change="applyFilter('dateRange', $event)"
              )
        .row.table-row
          .table
            VueGoodTable(
              :rows="reservations"
              :columns="columns"
              :sort-options="{ initialSortBy: sorting }"
              @on-sort-change="changeSorting"
              @on-row-click="selectReservation"
            )
              template(v-slot:table-column="props")
                span(:title="props.column.label") {{ $t(props.column.label) }}
          .paginator
            AppPagination(
              :current-page="pagination.current_page"
              :total="pagination.total_count"
              :per-page="pagination.per_page"
              @change-pagination-data="changePagination"
            )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // components
  import { VueGoodTable } from "vue-good-table"

  // misc
  import { isEqual } from "lodash-es"
  import { appDebounce } from "@/helpers/common"
  import { columns } from "./bookingTableConfig"
  import "vue-good-table/dist/vue-good-table.css"

  // store modules
  import bookingConfirmationModule from "@/config/store/ota_management/booking_confirmation"
  import companiesModule from "@/config/store/ota_management/companies"
  import shopsModule from "@/config/store/ota_management/shops"

  const bookingConfirmationMixin = withStoreModule(bookingConfirmationModule, {
    name: "otaManagementBookingConfirmationMixin",
    resetState: true,
    readers: {
      reservations: "items",
      loading: "loading",
      filters: "filters",
      pagination: "pagination",
      sorting: "sorting"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      changeFilters: "SET_FILTERS",
      setSorting: "SET_SORTING"
    },
    actions: {
      fetchReservations: "FETCH_ITEMS"
    }
  })

  const copmpaniesMixin = withStoreModule(companiesModule, {
    name: "otaManagementCompaniesMixin",
    readers: { companies: "items" },
    actions: { fetchCompanies: "FETCH_ITEMS" }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    name: "otaManagementShopsMixin",
    readers: { shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  export default {
    components: {
      VueGoodTable,
      Form: () => import("./Form"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker")
    },

    mixins: [bookingConfirmationMixin, copmpaniesMixin, shopsMixin],

    created() {
      this.debouncedFetchReservations = appDebounce(() => this.fetchReservations())
    },

    mounted() {
      this.fetchReservations()
      this.fetchCompanies()
      this.fetchShops()
    },

    data() {
      return {
        columns,
        selectedReservationId: null,
        // reservations: dummyReservations,
        periods: [
          {
            id: "application_date",
            name: this.$t("company_system.booking_confirmation.filters.application_date")
          },
          {
            id: "loan_date",
            name: this.$t("company_system.booking_confirmation.filters.loan_date")
          },
          {
            id: "return_date",
            name: this.$t("company_system.booking_confirmation.filters.return_date")
          }
        ]
      }
    },

    methods: {
      changeSorting([sortingData]) {
        if (isEqual(sortingData, this.sorting)) return

        this.setSorting(sortingData)
        this.fetchReservations()
      },

      applyFilter(itemType, items) {
        this.changeFilters({ ...this.filters, [itemType]: items })
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.debouncedFetchReservations()
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchReservations()
      },

      selectReservation({ row }) {
        this.selectedReservationId = row.id
      },

      unselectReservation() {
        this.selectedReservationId = null
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .booking-confirmation
    ::v-deep
      .table-row
        margin-top: 30px

        .paginator
          width: 100%
          display: flex
          justify-content: right

      .table
        &-th
          background: $default-gray-light !important

          button
            &:before,
            &:after
              border-left-width: 4px
              border-right-width: 4px

    .filer-bar
      margin-top: 20px

      .filter
        +filter-title
        .filter-title
          margin-bottom: 0
          margin-left: 0

        min-width: 210px
        max-width: 25%
        padding: 0

        ::v-deep
          .app-select
            padding-left: 0

        .period
          display: flex
          justify-content: left

          .app-select
            width: 96px

          .datepicker
            margin-top: 7px

            ::v-deep
              input
                height: 34px
</style>
