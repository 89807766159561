import { configPrepare } from "@/helpers/vue-good-table"

const i18nPrefix = "ota_management.booking_confirmation.columns"
const defaults = {
  thClass: "table-th"
}

const fields = [
  {
    label: `${i18nPrefix}.status`,
    field: "status"
  },
  {
    label: `${i18nPrefix}.company`,
    field: "company_name"
  },
  {
    label: `${i18nPrefix}.pick_up_shop`,
    field: "pick_up_shop_name"
  },
  {
    label: `${i18nPrefix}.reservation_number`,
    field: "reservation_number"
  },
  {
    label: `${i18nPrefix}.reserver`,
    field: "reserver_name"
  },
  {
    label: `${i18nPrefix}.user`,
    field: "user_name"
  },
  {
    label: `${i18nPrefix}.application_date`,
    field: "application_date"
  },
  {
    label: `${i18nPrefix}.loan_date`,
    field: "loan_date"
  },
  {
    label: `${i18nPrefix}.return_date`,
    field: "return_date"
  }
]

export const columns = configPrepare(fields, { i18nPrefix, defaults })
