import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import { mappedDateRange } from "@/config/date_helpers"
import { map } from "lodash-es"

import extractMutations from "./mutations"
import extractActions from "./actions"

const mapFilters = filters => {
  return {
    shop_ids: map(filters.shops, "id"),
    company_ids: map(filters.companies, "id"),
    status: map(filters.status, "id"),
    date_range: mappedDateRange(filters.dateRange),
    period: filters.period?.id,
    search_value: filters.searchValue
  }
}

const baseURI = "/ota_management/reservations"

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch"],
  withFilters: {
    shops: [],
    companies: [],
    status: [],
    dateRange: null,
    periodType: null,
    searchValue: ""
  },
  withSorting: {
    field: "status",
    direction: "desc"
  },
  withPagination: true,
  mapFilters
})

store.mergeState({
  item: {}
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
